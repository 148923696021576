import axios from 'axios'
import moment from 'moment'

export function formatDate(date) {
  let dd = date.getUTCDate();
  let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = date.getFullYear();
  return yyyy + '-' + mm + '-' + dd
}

const nth = (d) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
    }
};

export function formatPrettyDate(date) {
  const newDate = moment(date, ["YYYY-MM-DD"]).format('dddd MMM. Do')
  return newDate
//   console.log('NEW DATE', newDate)
//   const months = ["January", "February", "March", "April", "May", "June", "July","August", "September", "October", "Nov", "December"]
//   const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
// //   let dd = newDate.getUTCDate();
//   let day = date ? moment.day(newDate.getDay()) : ''
//   let dayDate = date ? newDate.getDate() : ''
//   console.log('DAY DATE', dayDate)
//   let dayOrdinal = dayDate ? nth(dayDate) : ''
//   let mm = months[newDate.getMonth()]
//   let yyyy = newDate.getFullYear();
//   return `${day} ${mm}. ${dayDate}${dayOrdinal}`
//   return `${day}`
}

export var objectToFormData = (obj, form, namespace) => {
  var fd = form || new FormData();
  var formKey;
  for(var property in obj) {
    if(obj.hasOwnProperty(property)) {
      if(namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }
      // if the property is an object, but not a File,
      // use recursivity.
      if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, property);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

export function htmlEncodeInputDisplay(string) {
    if (string == null || jQuery.trim(string) == "") return string;
    string = string.replace(/&lt;/g, "<");
    string = string.replace(/&gt;/g, ">");
    string = string.replace(/&amp;/g, "&");
    string = string.replace(/&nbsp;/g, " ");
    string = string.replace(/\"/g, "&quot;");
    string = string.replace(/&#8221;/g, '"');
    string = string.replace(/&#8220;/g, '"');
    string = string.replace(/&#8211;/g, '-');
    return string;
}

export function fetchEvents (param, event_type) {
  let data = {
    action: 'do_ajax',
    fn : 'get_events',
    dataType: 'json',
    param: param,
    event_type: event_type
  }
  return axios.post( ajaxurl, objectToFormData(data) )
  .then(results => {
    return results.data
  })
  .catch( error => error )
}

export function fetchAllEvents () {
  console.log('nonce', eventsNonce)
  let fn = nycjwEventSettings.selected_term ? 'get_events_by_term' : 'get_all_events'
  let data = {
    action: 'event_functions',
    fn : fn,
    dataType: 'json',
    // _ajax_nonce: eventsNonce
  }
  if ( nycjwEventSettings.selected_term ) {
    data.term = nycjwEventSettings.selected_term
  }

  // const eventData = axios.post( ajaxurl, objectToFormData(data) )
  //   .then(results => {
  //     return results.data
  //   })
  //   .then(events => {
  //     console.log(console.log('results', events))
  //     return events
  //   })
  //   .catch( error => {
  //     console.log('error', error)
  //     return error 
  //   })
  const eventData = fetch(ajaxurl, {
    method: 'POST',
    body: objectToFormData(data),
    headers: {
      'Accept': 'application/json',
    },
  })
  .then( response => {
    
    const responseJSON = response.json()
    console.log('response json', responseJSON);
    return responseJSON
  })
  .then( results => {
    console.log('results', results)
    return results 
  })
  // console.log('event data', eventData)
  return eventData
}

export function fetchEventContent (slug, intro) {
  let data = {
    action: 'do_ajax',
    fn : 'get_event_by_slug',
    dataType: 'json',
    event: slug,
    intro
  }
  return axios.post( ajaxurl, objectToFormData(data) )
  .then(results => {
    if ( !results.data ) {
      return
    }
    return results.data
  })
  .catch( error => console.log(error) )
}

export function fetchEventContentById (slug, intro, supporting) {
  let data = {
    action: 'do_ajax',
    fn : 'get_event_content_by_id',
    dataType: 'json',
    event: slug,
    intro,
    supporting
  }
  return axios.post( ajaxurl, objectToFormData(data) )
  .then(results => {
    if ( !results.data ) {
      return
    }
    return results.data
  })
  .catch( error => console.log(error) )
}

export function parseTime( t ) {
   var d = ''
   var time = t.match( /(\d+)(?::(\d\d))?\s*(p?)/ );
   d += parseInt( time[1]) + (time[3] ? 12 : '00');
   d += ':'
   d += parseInt( time[2]) || '00'
   d += ':00'
   return d;
}

export function beautyTime(t) {
  const express = /^([01][0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/;
  if (express.test(t)) {
    let hour = Number(t.split(":")[0]);
    let minute = t.split(":")[1];
    let type = 'PM';
    if (hour > 11) {
      if (hour > 12) {
        hour = hour - 12;
      }
      if (hour == 24) {
        hour = 12;
      }
    } else {
      type = 'AM';
    }
    if ( hour == 0 ) {
      hour = 12
    }
    return (hour < 10 ? `${hour}` : hour) + ":" + minute + " " + type;
  } else {
    return '1:00 AM'
  }
}

export function alphabetically(a, b) {
  // Use toUpperCase() to ignore character casing
  const eventA = a.title.toUpperCase();
  const eventB = b.title.toUpperCase();

  let comparison = 0;
  if (eventA > eventB) {
    comparison = 1;
  } else if (eventA < eventB) {
    comparison = -1;
  }
  return comparison;
}

export function by_start_time(a, b) {
  // Use toUpperCase() to ignore character casing
  const eventA = a.start_time ? moment(a.start_time, ["h:mm A"]).format("HH:mm:ss") : moment('23:59:59', ["h:mm A"]).format("HH:mm:ss")
  const eventB = b.start_time ? moment(b.start_time, ["h:mm A"]).format("HH:mm:ss") : moment('23:59:59', ["h:mm A"]).format("HH:mm:ss")
  if ( a.intro || b.intro ) return 1
  let comparison = 0;
  if (eventA > eventB) {
    comparison = 1;
  } else if (eventA < eventB) {
    comparison = -1;
  }
  return comparison;
}

export function by_start_date_time(a, b) {
  let eventAStartDate = a.date ? a.date : a.start_date
  let eventBStartDate = b.date ? b.date : b.start_date

  let eventAStartTime = (a.dates && a.dates.length) ? a.dates[0].start_time : a.start_time
  let eventBStartTime = (b.dates && b.dates.length) ? b.dates[0].start_time : b.start_time
  // console.log(eventAStartDate)
  if ( a.dates && a.dates.length ) {
    eventAStartDate = a.dates[0].event_date
    eventAStartTime = a.dates[0].start_time
  }
  if ( b.dates && b.dates.length ) {
    eventBStartDate = b.dates[0].event_date
    eventBStartTime = b.dates[0].start_time
  }
  
  const eventADateStringStart = `${eventAStartDate} ${eventAStartTime ? (moment(eventAStartTime, ["h:mm A"]).format("HH:mm:ss"))  : moment( eventAStartDate + 'T06:00:00', ["h:mm A"]).format("HH:mm:ss")}`
  const eventBDateStringStart = `${eventBStartDate} ${eventBStartTime ? (moment(eventBStartTime, ["h:mm A"]).format("HH:mm:ss"))  : moment( eventBStartDate + 'T06:00:00', ["h:mm A"]).format("HH:mm:ss")}`
  
  const eventATimeStart = new Date(eventADateStringStart.replace(/-/g, "/")).getTime();
  const eventBTimeStart = new Date(eventBDateStringStart.replace(/-/g, "/")).getTime();
  
  let eventAEndDate = a.end_date ? a.end_date : a.date
  let eventBEndDate = b.end_date ? b.end_date : b.date

  if ( a.dates && a.dates.length > 1 ) {
    eventAEndDate = a.dates[a.dates.length - 1].event_date
  }
  if ( b.dates && b.dates.length > 1 ) {
    eventBEndDate = b.dates[b.dates.length - 1].event_date
  }
  
  let eventAEndTime = (a.dates && a.dates.length) ? a.dates[0].end_time : a.end_time
  let eventBEndTime = (b.dates && b.dates.length) ? b.dates[0].end_time : b.end_time
  
  const eventADateStringEnd = `${eventAEndDate} ${eventAEndTime ? (moment(eventAEndTime, ["h:mm A"]).format("HH:mm:ss")) : false}`
  const eventBDateStringEnd = `${eventBEndDate} ${eventBEndTime ? (moment(eventBEndTime, ["h:mm A"]).format("HH:mm:ss")) : false}`
  // console.log('title:', a.title, 'end time string', eventAEndTime, 'END TIME:', eventADateStringEnd, 'event:', a)
  const eventATimeEnd = new Date(eventADateStringEnd.replace(/-/g, "/")).getTime();
  const eventBTimeEnd = new Date(eventBDateStringEnd.replace(/-/g, "/")).getTime();
  
  let comparison = 0;

  if ( eventAStartDate && eventBStartDate ) {
    if (new Date(eventAStartDate).getDay() < new Date(eventBStartDate).getDay() ) {
      comparison = -1
    }
    if (new Date(eventAStartDate).getDay() > new Date(eventBStartDate).getDay() ) {
      comparison = 1
    }
  }

  // if ( a.id === 19948 ) {
  //   console.log('start date:', eventAStartDate, 'compare start date:', comparison)
  // }
  
  if ( comparison === 0 && eventAEndDate && eventBEndDate ) {
    if ( new Date(eventAEndDate).getDay() < new Date(eventBEndDate).getDay() ) {
      comparison = -1
    }
    if ( new Date(eventAEndDate).getDay() > new Date(eventBEndDate).getDay() ) {
      comparison = 1
    }
  }

  // if ( a.id === 19948 ) {
  //   console.log('end date', eventAEndDate, 'comparison end date:', comparison)
  // }

  if ( comparison === 0 && eventATimeStart && eventBTimeStart ) {
    if (eventATimeStart < eventBTimeStart ) {
      comparison = -1 
    }
    if ( eventATimeStart > eventBTimeStart ) {
      comparison = 1
    }
  }

  // console.log('title:', a.title, 'start time:', eventATimeStart, 'comparison start time:', comparison)

  if ( comparison === 0 && eventATimeEnd && eventBTimeEnd ) {
    if (eventATimeEnd < eventBTimeEnd ) {
      comparison = -1 
    }
    if ( eventATimeEnd > eventBTimeEnd ) {
      comparison = 1
    }
  }

  // console.log('title:', a.title, 'end time:', eventATimeEnd, 'comparison end time:', comparison)

  return comparison;
}

export function by_end_date_time(a, b) {
  let eventAEndDate = a.end_date ? a.end_date : a.date
  let eventBEndDate = b.end_date ? b.end_date : b.date
  if ( a.dates ) {
    eventAEndDate = a.dates[a.dates.length - 1].event_date
  }
  if ( b.dates ) {
    eventBEndDate = b.dates[b.dates.length - 1].event_date
  }

  let comparison = 0;
  if (eventAEndDate > eventBEndDate) {
    comparison = 1;
  } else if (eventAEndDate < eventBEndDate) {
    comparison = -1;
  }

  return comparison
}


export const moveElementToEndOfArray = (arr, x) => {
  // Array is [1, 2, 3, 4, 5] and x = 2
  // final output would be [3, 4, 5, 1, 2]
  const eventToMove = arr[x]

  let newArray = arr.filter( (item, index) => index !== x )

  // After this loop array will 
  // be [1, 2, 3, 4, 5, 1, 2]
  newArray.push(eventToMove)

  // Splice method will remove first
  // x = 2 elements from the array
  // so array will be [3, 4, 5, 1, 2]

  return newArray
}

export const moveElementToStartOfArray = (arr, x) => {
  // Array is [1, 2, 3, 4, 5] and x = 2
  // final output would be [3, 4, 5, 1, 2]
  const eventToMove = arr[x]

  let newArray = arr.filter( (item, index) => index !== x )

  // After this loop array will 
  // be [1, 2, 3, 4, 5, 1, 2]
  newArray.unshift(eventToMove)

  // Splice method will remove first
  // x = 2 elements from the array
  // so array will be [3, 4, 5, 1, 2]

  return newArray
}
