import $ from 'jquery';
import 'slick-carousel';
import Masonry from 'masonry-layout'

var $slickInstance;
var $eventsSlick;
var eventImgInt;
var totalImages = 0;
var currentImgInd = 3;
var eventImgCount;
var eventImgCounter = 0;
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const anchorTag = this.getAttribute('href').replace('#', '')
        if ( anchorTag !== '' ) {
          const anchorName = document.querySelector('a[name="' + anchorTag + '"')
          if ( anchorName ) {
            anchorName.scrollIntoView({
              behavior: 'smooth'
            });
          }
          const anchorDiv = document.querySelector('#' + anchorTag)
          if ( anchorDiv ) {
            anchorDiv.scrollIntoView({
              behavior: 'smooth'
            });
          }
        }
    });
});

$(window).on( 'resize', function() {
  setMainPadding()
})

$(document).on( "scroll", function(){
  animateDivs()
  if ( $('#root').length ) {
    return;
  }
	if ( $(document).scrollTop() > 100 && !$('.site-header').hasClass('shrink') ) {
	  $('.site-header').addClass('shrink');
	}
  if ( $(document).scrollTop() <= 100 && $('.site-header').hasClass('shrink') ) {
		$('.site-header').removeClass('shrink');
	}
});

$(document).ready(function() {
  setMainPadding()
  animateDivs()
  $('.loader').fadeOut('fast');
  $('#fullpage').removeClass('hidden');
  $('body').addClass('loaded');
  //if($('body').hasClass('hide-intro')) {
    $('.hashtag-link, .site-branding').toggleClass('active');
  //}
  $('.menu-item-has-children > a').on('click', function (e) {
    if ( $(window).width() < 700 ) {
      const menuParent = $(this).parent()
      if ( !menuParent.hasClass('active') ) {
        e.preventDefault()
        $('.menu-item-has-children').removeClass('active')
        menuParent.addClass('active')
      }
    }
  })

//   $('.mail-icon').on('click', function () {
//     $("html, body").animate({ scrollTop: $(document).height() });
//     $('#contact-info .jw-email').focus()
//   })

  // Partners
  if (document.cookie.length > 0) {
    if(document.cookie.indexOf("newsletter_popup=") == -1) {
      setTimeout(function() {
        $('.join-list').click();
      }, 10000)
    }
  }

  if ( $('.partner-list-container').length ) {
    const grid = document.querySelector('.partner-list-container')
    const msnry = new Masonry(grid, {
      itemSelector: '.partner-list',
      columnWidth: '.partner-list',
      percentPosition: true,
      gutter: '.partner-list-gutter'
    })
  }

  if ( $('.testimonials-wrapper').length ) {
    $slickInstance = $('.testimonials-wrapper').slick({
      'arrows': true,
      'slidesToShow': 1,
      'slidesToScroll': 1,
      'autoplay': true,
      'autoplaySpeed': 10000,
      'fade': true,
      'infinite': true,
      'prevArrow' : $('.testimonial-arrow-left'),
			'nextArrow' : $('.testimonial-arrow-right'),
    });
  }

  if ( $('.nycjw-slideshow-block').length ) {
    const $slideshow = this
    $('.nycjw-slideshow', this).each(function(i, gallery) {
      $(this).slick({
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        //'autoplay': true,
        //'autoplaySpeed': 1000,
        lazyLoad: 'ondemand',
        fade: false,
        infinite: true,
        // centerMode: true,
        focusOnSelect: false,
        variableWidth: false,
        prevArrow : $('.gallery-arrow-prev', $slideshow),
				nextArrow : $('.gallery-arrow-next', $slideshow),
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              // 'arrows': false
            }
          },
          {
            breakpoint: 688,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 496,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
    });
  }

  if ( $('.item-carousel-wrapper').length ) {
    $('.item-carousel-wrapper').each(function(i, gallery) {
      $('.item-carousel', gallery)
      .on('init', function () {
        let src = $('.slick-current .lazy-item', gallery).attr("data-background-image");
				$('.slick-current .lazy-item', gallery).css('background-image', `url(${src})`);
      })
      .slick({
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      })
      .on('afterChange', function(event, slick, currentSlide, nextSlide) {
        let src = $('.slick-current .lazy-item', gallery).attr("data-background-image");
				$('.slick-current .lazy-item', gallery).css('background-image', `url(${src})`);
      })
    })
  }

  if ( $('.wp-gallery').length ) {
    $('.wp-gallery').each(function(i, gallery) {
      $(this).addClass('gallery-'+i);
      $('.wp-gallery-images', this).slick({
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        infinite: true,
        asNavFor: '.gallery-'+i+' .wp-gallery-nav-images',
      });
      $('.wp-gallery-nav-images', this).slick({
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        //'autoplay': true,
        //'autoplaySpeed': 1000,
        fade: false,
        infinite: true,
        centerMode: true,
        focusOnSelect: true,
        variableWidth: true,
        asNavFor: '.gallery-'+i+' .wp-gallery-images',
        prevArrow : $('.arrow-prev', this),
				nextArrow : $('.arrow-next', this),
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: $(gallery).data('count') < 5 ? $(gallery).data('count') : 5,
              // 'arrows': false
            }
          },
          {
            breakpoint: 688,
            settings: {
              slidesToShow: $(gallery).data('count') < 4 ? $(gallery).data('count') : 4,
              arrows: false
            }
          },
          {
            breakpoint: 496,
            settings: {
              slidesToShow: $(gallery).data('count') < 3 ? $(gallery).data('count') : 3,
              arrows: false
            }
          }
        ]
      });
    });
  }

  if ( $('.vendor-gallery').length ) {
    $('.vendor-gallery noscript').remove();
    $('.vendor-gallery').slick({
      'arrows': true,
      'slidesToShow': 1,
      'slidesToScroll': 1,
      'autoplay': true,
      'autoplaySpeed': 5000,
      'fade': true,
      'infinite': true,
      'adaptiveHeight' : true,
      'prevArrow' : $('.vendor-arrow-left'),
			'nextArrow' : $('.vendor-arrow-right'),
    });
  }

  if ( $('#events-gallery').length ) {
    startImageRotation();
  }

  $('.menu-toggle').on('click', function() {
    $('body').toggleClass('menu-active');
  });

  if ( $('.extend-content').length ) {
    $('.extend-content').on('click', function (e) {
      e.preventDefault()
      const $extendButton = $(this)
      $extendButton.parent('.extended-content-wrapper').find('.extended-content-container').slideToggle()
      $extendButton.toggleClass('active')
      const buttonLabel = $extendButton.hasClass('active') ? 'show less' : 'read more'
      $extendButton.text(buttonLabel)
    })
  }

  if ( $('.join-list, .mail-icon').length ) {
    $('.join-list, .mail-icon').on('click', function(e) {
      e.preventDefault();
      $('body').addClass('signup-active');
    });
  }
  if ( $('#close-newsletter').length ) {
    $('#close-newsletter').on('click', function(e){
      e.preventDefault();
      $('body').removeClass('signup-active');
      $('.jw-email').val('');
      setNewsletterCookie();
    });
  }

  if ( $('.jw-newsletter-form').length ) {
    $('.jw-newsletter-form').on('submit', function(e) {
      e.preventDefault();
      $('.jw-email', e.target).removeClass('error');
      var email = $('.jw-email', e.target).val();
      if(email.length){
        $(e.target).parent().find('.newsletter-container').addClass('loading');
        signup_user(email, e.target);
        setNewsletterCookie();
      } else {
        $('.jw-email', e.target).addClass('error');
      }
    });
  }
});

if ( $('.open-discover-modal') ) {

  const $slider = $('#discover-modal__carousel')

  $('.open-discover-modal').on('click', function () {
    openDialog('discover-modal', this)
    if ( !$slider.hasClass('slick-initialized') ) {
      $('#discover-modal__carousel').slick({
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        lazyLoad: 'ondemand',
        infinite: false,
        prevArrow : $('.arrow-prev'),
        nextArrow : $('.arrow-next')
      })
    }
    const slideIndex = $(this).data('index')
    $('#discover-modal__carousel').slick('slickGoTo', slideIndex, true)
  })

  $('#discover-modal').on('click', function (e) {
    if ( e.target === this ) {
      closeDialog(this)
    }
  })
}

function setMainPadding () {
  $('.site-main').css('padding-top', $('.site-header').height())
}

function startImageRotation() {
  eventImgCount = $('.event-gallery-image').length;
  if(typeof eventImages != 'undefined') {
    totalImages = eventImages.length;
  }
  if(eventImgCount < totalImages) {
    var newImageCount = $('.event-gallery-image:visible').length;
    eventImgInt = setInterval(function(){
      newImageCount = $('.event-gallery-image:visible').length;
      var currentEventImg = $('.event-gallery-image').eq(eventImgCounter);
      var currentImg = eventImages[currentImgInd];
      $('.temp-event-image', currentEventImg).css('background-image', 'url('+currentImg+')').addClass('active');
      setTimeout(function() {
        $(currentEventImg).css('background-image', 'url('+currentImg+')');
        $('.temp-event-image', currentEventImg).removeClass('active');
      }, 400);
      eventImgCounter = (eventImgCounter === eventImgCount - 1)
        ? 0
        : eventImgCounter+1;
      currentImgInd = (currentImgInd === totalImages -1)
        ? 0
        : currentImgInd+1;

    }, newImageCount * 500);
  }
}

function setNewsletterCookie () {
  var date = new Date();
  date.setTime(date.getTime()+365*24*60*60*1000); // ) removed
  var expires = '; expires=' + date.toGMTString(); // + added
  document.cookie = 'newsletter_popup=1' +expires + ';path=/';
}

function signup_user(email, form) {
  $.ajax({
    url: ajaxurl,
    method: 'post',
    type: 'json',
    data: {
      'action': 'do_ajax',
      'fn' : 'sign_up_user',
      'email' : email
    }
  }).done( function (response) {
    $(form).parent().removeClass('loading');
    response = $.parseJSON(response);
    $(form).parent().find('.newsletter-signup-text').html('<div class="response-text">'+response.response+'</div>');
    $('.jw-email', form).val('');
  });
}

function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

function animateDivs () {
  if ( $('.animate').length ) {
    $('.animate').each( function () {
      const $animatedDiv = this
      if ( $($animatedDiv).isInViewport() && !$($animatedDiv).hasClass('animated') ) {
        $($animatedDiv).addClass("animated");
      }
    })
  }
}

$.fn.isInViewport = function() {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();
  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height() * .75;
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

if ( $('#featured-articles .article-repeater').length ) {
  $('#featured-articles .article-repeater .featured-article-wrapper').each( function () {
    if ( $(this).data('background-image') ) {
      const backgroundImage = $(this).data('background-image')
      $(this).css('background-image', `url(${backgroundImage})`)
    }
  })
  $('#featured-articles .article-repeater').slick({
    'arrows': true,
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'autoplay': true,
    'autoplaySpeed': 5000,
    'fade': true,
    'infinite': true,
    'adaptiveHeight' : true,
    'prevArrow' : $('.arrow-prev.featured_article', this),
    'nextArrow' : $('.arrow-next.featured_article', this)
  });
}

if ( $('#edit-hwa-form').length ) {
    
  $('.toggle-edit-form').on('click', function () {
    $('#edit-hwa-form').toggleClass('active')
  })

//   var instance = new acf.Model({
//       initialize: function(){
          
//       }
//   });

  instance.on('keyup', 'input[type="text"]', function( e ){
      // similar to jQuery, "this" is scoped to the DOM element.
      if ( e.currentTarget.getAttribute('name') === 'acf[_post_title]' ) {
        $('.vendor-info h1').html(e.target.value);
      }
      if ( e.currentTarget.closest('.acf-field').getAttribute('data-name') === 'hwa_country' ) {
        let countryVal = e.target.value
        if ( countryVal.charAt(countryVal.length - 1) !== ',' && $('[data-name="hwa_state"] select option:checked').length )  {
          countryVal = countryVal + ','
        }
        $('.hwa-country').html(countryVal);
      }
  });
  instance.on('change', 'select', function( e ){
    if ( e.currentTarget.closest('.acf-field').getAttribute('data-name') === 'hwa_state' ) {
      $('.hwa-state').html($(e.target).find('option:checked').html());
    }
    if ( e.currentTarget.closest('.acf-field').getAttribute('data-name') === 'year_established' ) {
        $('.hwa-estd').html($(e.target).find('option:checked').html());
    }
});
}