import React, { Component } from 'react';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import PreviewEventSlider  from './PreviewEventSlider';
import PreviewVirtualBoothSlider  from './PreviewVirtualBoothSlider';
import axios from 'axios';
import { objectToFormData } from '../events/utils';

class App extends Component {
  constructor(props) {
    super(props);
    this.onClickPreviewButton = this.onClickPreviewButton.bind(this)
    this.hidePreviewModal = this.hidePreviewModal.bind(this)

    this.state = {
      eventContent: null,
      showPreviewModal: false,
      isLoading: false
    }
    const self = this;
    $('.edit-event-button').on('click', function(e) {
      e.stopPropagation();
    });

    $('.my-event-row').on('click',function() {
      let event_id = $(this).attr('data-id');
      let payload = {
        action: 'get_event_by_id',
        post_id: event_id
      };
      console.log('getting event by id', event_id);
      self.setState({
        isLoading: true
      });
      axios.post(ajaxurl, objectToFormData(payload)).then(res => {
        let eventContent = {
          featured_image: {
            src: res.data.event_image[0],
            alt: res.data.title
          },
          virtual_event: res.data.virtual_event,
          durational: true,
          title: res.data.title,
          content: res.data.description,
          date: res.data.event_dates.length ? res.data.event_dates[0].event_date : false,
          start_time: res.data.event_dates.length ? res.data.event_dates[0].start_time : false,
          end_time: res.data.event_dates.length ? res.data.event_dates[0].end_time : false,
          event_themes: [],
          event_types: res.data.event_types,
          images: [
            {
              src: res.data.event_image[0],
              alt: res.title
            },
            // {
            //   src: res.data.listing_image[0],
            //   alt: res.title
            // }
          ],
          rsvp: {
            url: res.data.rsvp_link?res.data.rsvp_link:''
          },
          link: {
            title: 'event link',
            url: res.data.event_url?res.data.event_url:'',
            target: '_blank'
          },
          participants: res.data.participants,
          supportEvents: res.data.supportEvents,
          isAvailableSubmit: false
        }
        self.setState({
          eventContent: eventContent,
          showPreviewModal: true,
          isLoading: false
        })
      })
    })
  }

  hidePreviewModal() {
    this.setState({
      showPreviewModal: false,
      eventContent: null
    })
  }

  onClickPreviewButton() {
    let event_title = $('input[name="acf[_post_title]"]').val();
    let event_subtitle = $('input[name="acf[field_61082ceb5835c]"]').val();
    let event_description = $('textarea[name="acf[field_6101af2029977]"]').val() || $('textarea[name="acf[field_610c4a329ca34]"]').val();
    let event_types = null;
    let event_dates = [];
    let dates = [];
    let end_date = null;
    let startTimes = [];
    let endTimes = [];
    let event_images = [];
    let participants = [];
    let event_location = $('input[name="acf[field_6102d3958f7ad]"]').val();
    let access_instructions = $('textarea[name="acf[field_614241bdb9291]"]').val();
    let event_url = $('input[name="acf[field_6102d3a78f7ae]"]').val();
    let event_type = $('div.acf-field-6102d45ac985c label.selected input').val();
    let project_website_url = $('input[name="acf[field_6102d6f7c9864]"]').val() || $('input[name="acf[field_610c49dd9ca2f]"]').val();
    let virtual_event = $('input[name="acf[field_6102d33c8f7ac]"]:checked').val();
    let supportEvents = [];
    let event_length = $('input[name="acf[field_6102d45ac985c]"]:checked').val()
    switch (event_length) {
      case 'single':
        if ( $('input[name="acf[field_610445bf88bbe]"]').val() ) {
          let date = $('input[name="acf[field_610445bf88bbe]"]').val()
          dates.push(`${date.substring(0,4)}-${date.substring(4,6)}-${date.substring(6,8)}`);
        }
        let start_time = $('input[name="acf[field_6102d59bc985f]"]').val();
        if ( start_time) {
          startTimes.push(start_time);
        }

        let end_time = $('input[name="acf[field_6104437ebeafd]"]').val();
        if ( end_time ) {
          endTimes.push(end_time);
        }
      break;
      case 'multiple':
        let date_wrappers = $('input[id*="-field_6101afb0bb2fb"]');
        for(let i = 0; i < date_wrappers.length; i ++) {
          let date = $(date_wrappers[i]).val();
          if (date.length > 4) {
            dates.push(`${date.substring(0,4)}-${date.substring(4,6)}-${date.substring(6,8)}`);
          }
        }
        let start_time_wrappers = $('input[id*="-field_6101b049bb2fc"]');
        for(let i = 0; i < start_time_wrappers.length; i ++) {
          let start_time = $(start_time_wrappers[i]).val();
          if ( start_time ) {
            startTimes.push(start_time);
          }
        }

        let end_time_wrappers = $('input[id*="-field_6101b065bb2fd"]');
        for(let i = 0; i < end_time_wrappers.length; i ++) {
          let end_time = $(end_time_wrappers[i]).val();
          if ( end_time ) {
            endTimes.push(end_time);
          }
        }
      break;
      case 'range':
        if ( $('input[name="acf[field_6102d535c985d]"').val() ) {
          let date = $('input[name="acf[field_6102d535c985d]"]').val()
          dates.push(`${date.substring(0,4)}-${date.substring(4,6)}-${date.substring(6,8)}`);
        }
        if ( $('input[name="acf[field_6104435dbeafc]"]').val() ) {
          let date = $('input[name="acf[field_6104435dbeafc]"]').val()
          end_date = `${date.substring(0,4)}-${date.substring(4,6)}-${date.substring(6,8)}`;
        }

        start_time = $('input[name="acf[field_6102d59bc985f]"]').val();
        if ( start_time ) {
          startTimes.push(start_time);
        }

        end_time = $('input[name="acf[field_6104437ebeafd]"]').val();
        if ( end_time ) {
          endTimes.push(end_time);
        }
      break;
      default:

    }

    let event_types_wrapper =  $('select[name="acf[field_61019cf3ecc1f]"] option');
    if (event_types_wrapper.length > 0) {
      let event_type_name = $(event_types_wrapper[event_types_wrapper.length - 1]).html();
      let event_type_id = $(event_types_wrapper[event_types_wrapper.length - 1]).attr('value');
      event_types = [{
        name: event_type_name,
        id: event_type_id
      }];
    }

    let event_image = '';
    let img = $('div.acf-field[data-key="field_5d44946755dce"] img');
    if (img.length > 0) {
      event_image = $('div.acf-field[data-key="field_5d44946755dce"] img').attr('src');
      if (event_image.length > 1) {
        event_images.push({
          src: event_image,
          alt: event_title
        });
      }
    } else {
      event_image = $('.acf-gallery-main img')
      if (event_image.length > 1) {
        event_image.each( (i, image) => {
          event_images.push({
            src: image.src,
            alt: image.alt
          });
        })
      }
    }

    let listing_image = '';
    let listing_img = $('div.acf-field[data-key="field_61019a86ecc1e"] img');
    if (listing_img.length > 0) {
      listing_image = $('div.acf-field[data-key="field_61019a86ecc1e"] img').attr('src');
      if (listing_image.length > 1) {
        // event_images.push({
        //   src: listing_image,
        //   alt: event_title
        // });
      }
    }

    let location_address = false

    if ($('input[name="acf[field_610ab9f5d130a]"]').length && $('input[name="acf[field_610ab9f5d130a]"]').val() !== '' ) {
      location_address = JSON.parse($('input[name="acf[field_610ab9f5d130a]"]').val())
    }
    let location_name = $('input[name="acf[field_614242c3b9292]"]').val();
    let social = [];
    let instagram = $('input[name="acf[field_61095669593be][field_61095674593bf]"], input[name="acf[field_610c49f49ca30][field_610c49fd9ca31]"]').val();
    if ( instagram ) {
      social.push({ type: 'instagram', link: instagram })
    }
    let tiktok = $('input[name="acf[field_61095669593be][field_6109567b593c0]"], input[name="acf[field_610c49f49ca30][field_610c4a069ca32]"]').val();
    if ( tiktok ) {
      social.push({ type: 'tiktok', link: tiktok })
    }
    let facebook = $('input[name="acf[field_61095669593be][field_610ab6f4f8f90]"]').val();
    if ( facebook ) {
      social.push({ type: 'facebook', link: facebook })
    }
    let contactInfo = {}
    let contactEmail = $('input[name="acf[field_610c4a719ca35][field_610c4a839ca36]"]').val();
    if ( contactEmail ) {
      contactInfo.email = contactEmail
    }
    let contactPhone = $('input[name="acf[field_610c4a719ca35][field_610c4a899ca37]"]').val();
    if ( contactPhone ) {
      contactInfo.phone = contactPhone
    }

    let rsvp_link = $('input[name="acf[field_5b719dfaf20c0]"]').val();
    let participants_names = [];
    let participants_bios = [];

    let participants_name_wrappers = $('input[id*="-field_5f735e538e3f9"]');
    for(let i = 0 ; i < participants_name_wrappers.length; i ++) {
      let participant_name = $(participants_name_wrappers[i]).val();
      participants_names.push(participant_name);
    }

    let participants_bio_wrappers = $('textarea[name*="[field_5f735e5a8e3fa]"]');
    for(let i = 0 ; i < participants_bio_wrappers.length; i ++) {
      let participant_bio = $(participants_bio_wrappers[i]).val();
      participants_bios.push(participant_bio);
    }
    for(let i = 0 ; i < participants_names.length; i ++) {
      if (participants_names[i].length > 0 || participants_bios[i].length > 0) {
        participants.push({
          name: participants_names[i],
          bio: participants_bios[i]
        });
      }
    }

    let supporting_event_name_wrappers = $('input[id*="-field_6101b124dec81"]');
    let supporting_event_types_wrapper =  $('select[id*="-field_6101b135dec82"] option');
    let supporting_event_date_wrappers = $('input[id*="-field_6101b18ddec83"]');
    let supporting_event_start_time_wrappers = $('input[id*="-field_6101b19edec84"]');
    let supporting_event_end_time_wrappers = $('input[id*="-field_6101b1a6dec85"]');
    let supporting_event_url_wrappers = $('input[id*="-field_614243de81ff9"]');
    let supporting_event_location_wrappers = $('input[name*="[field_6117d18b3d2c4]"]');

    for(let i = 0; i < supporting_event_name_wrappers.length; i ++) {
      let support_event_types = [];
      let support_event_type_name = $(supporting_event_types_wrapper[i]).html();
      let support_event_type_id = $(supporting_event_types_wrapper[i]).attr('value');
      if (support_event_type_name && support_event_type_name.length > 0) {
        support_event_types = [{
          name: support_event_type_name,
          id: support_event_type_id
        }];
      }
      let supporting_event = $(supporting_event_name_wrappers[i]).val();
      let supporting_date = $(supporting_event_date_wrappers[i]).val();
      let supporting_start_time = $(supporting_event_start_time_wrappers[i]).val();
      let supporting_end_time = $(supporting_event_end_time_wrappers[i]).val();
      let supporting_url = $(supporting_event_url_wrappers[i]).val()
      let supporting_location = $(supporting_event_location_wrappers[i]).val()
        ? JSON.parse($(supporting_event_location_wrappers[i]).val())
        : null
      supportEvents.push({
        event: supporting_event,
        date: supporting_date.length > 1 ? `${supporting_date.substring(0,4)}-${supporting_date.substring(4,6)}-${supporting_date.substring(6,8)}` : ``,
        event_types: support_event_types,
        start_time: supporting_start_time,
        end_time: supporting_end_time,
        url: supporting_url,
        location: supporting_location
      })
    }

    let eventContent = {
      featured_image: {
        src: event_image,
        alt: event_title
      },
      contactInfo: contactInfo,
      project_url: project_website_url,
      virtual_event: virtual_event,
      durational: event_type == 'durational',
      title: event_title,
      content: event_description,
      location_name: location_name,
      location_address: location_address,
      access_instructions: access_instructions,
      date: dates,
      end_date: end_date,
      start_time: startTimes,
      end_time: endTimes,
      event_themes: [],
      event_types: event_types,
      images: event_images,
      rsvp: {
        url: rsvp_link
      },
      social: social,
      participants: participants,
      link: {
        title: 'event link',
        url: event_url,
        target: '_blank'
      },
      supportEvents: supportEvents,
      isAvailableSubmit: true
    }
    this.setState({
      eventContent: eventContent,
      showPreviewModal: true
    })
  }
  render()
  {
    let { showPreviewModal } = this.state;
    return (
      <div>
        <button className="btn btn-preview" type="button" onClick={this.onClickPreviewButton} >
          Preview Event
        </button>
        {
          (showPreviewModal && $('.event-form').data('type') !== 'discover')
            ? <PreviewEventSlider key="preview-event-slider" eventContent={this.state.eventContent} dismissPreviewModal={this.hidePreviewModal}/>
            : ''
        }
        {
          (showPreviewModal && $('.event-form').data('type') === 'discover')
            ? <PreviewVirtualBoothSlider key="preview-event-slider" eventContent={this.state.eventContent} dismissPreviewModal={this.hidePreviewModal}/>
            : ''
        }
        {
          this.state.isLoading ?
          <div className="loading-status">
            Loading Event...
          </div>
          : ''
        }
      </div>
    );
  }
}

if ( document.getElementById('preview-wrapper') ) {
  ReactDOM.render(
    <App />,
    document.getElementById('preview-wrapper')
  )
}
