import React, { Component } from 'react'
import facebook from '../../images/fb.png';
import instagram from '../../images/instagram.png';
import twitter from '../../images/twitter.png';
import tiktok from '../../images/tiktok.png';
import Slider from "react-slick";
import $ from 'jquery';
import { formatPrettyDate, beautyTime } from '../events/utils';
const socialIcons = { facebook, instagram, tiktok, twitter }

class PreviewVirtualBoothSlider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      closing: false
    };
    this.slideoutRef = React.createRef();
    this.slideoutClose = React.createRef();
    this.closeSlideout = this.closeSlideout.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    const self = this;
    document.body.classList.add('modal-open');
    setTimeout(function() {
      self.setState({
        loading: false
      })
    }, 10);
  }

  submitForm() {
    $('form.acf-form').submit();
  }

  closeSlideout (event) {
    const self = this;

    if (this.slideoutRef && !this.slideoutRef.current.contains(event.target) || this.slideoutClose.current.contains(event.target) ) {
      event.preventDefault()
      const timeout = window.screen.width < 768 ? 0 : 300
      document.body.classList.remove('modal-open');
      this.setState({
        closing: true
      }, () => {
        setTimeout( () => {
          self.setState({
            closing: false
          });
          self.props.dismissPreviewModal();
        }, timeout)
      })
    }
    return
  }
  render () {

    let { eventContent } = this.props;
    let { loading, closing } = this.state;
    let selectedTestDay = 1;
    let selectedEventType  = 'durational';
    let borderColor = '#FEF200'
    if ( eventContent ) {
      if ( eventContent.event_themes && eventContent.event_themes.length > 0 ) {
        borderColor = eventContent.event_themes[0].color?eventContent.event_themes[0].color:'#FEF200';
      }
    }
    const settings = {
      dots: false,
      infinite: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    let eventStatus = 'future'
    let rsvpLink = null
    let rsvpLabel = 'RSVP'
    if ( eventContent ) {
      let today = new Date()
      if ( selectedTestDay !== 1 ) {
        today = new Date(selectedTestDay + `T${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`)
      }
      let eventStartDate = new Date( eventContent.date + 'T09:00:00')
      let eventEndDate = new Date( eventContent.date + 'T23:59:59')
      const weekStartDate = new Date('2020-11-14T09:00:00')
      rsvpLink = eventContent.rsvp ? eventContent.rsvp.url : null
      // If IG event, set as follow us on IG
      if ( eventContent.instagram_event ) {
        rsvpLink = 'https://instagram.com/nycjewelryweek'
        rsvpLabel = 'Follow us to RSVP'
      }
      // If theres an RSVP link, set the label as its text
      if ( eventContent.rsvp ) {
        rsvpLabel = eventContent.rsvp.title ? eventContent.rsvp.title : 'RSVP'
      }
      // If the event is on now
      if ( ( today > eventStartDate && today < eventEndDate ) || ( selectedEventType === 'durational' && today >  weekStartDate ) ) {
        eventStatus = 'present'
        rsvpLabel = 'Watch'
        if ( eventContent.instagram_event ) {
          rsvpLink = 'https://instagram.com/nycjewelryweek/live'
        } else {
          rsvpLink = 'https://youtube.com'
        }
        rsvpLink = eventContent.link ? eventContent.link.url : rsvpLink
        if ( eventContent.link ) {
          rsvpLabel = eventContent.link.title ? eventContent.link.title : rsvpLabel
          rsvpLink = eventContent.link.url
        }

      }
      if ( today > eventEndDate && selectedEventType !== 'durational' ) {
        eventStatus = 'past'
        rsvpLink = true
        rsvpLabel = 'Event over'
      }
    }
    // If todays date is after the event end date

    return (
      <div
        id="event-slideout"
        className={`${loading ? 'loading' : ''}${eventContent ? 'loaded' : ''}${closing ? ' closing' : ''}${eventContent.discover ? ' virtual-booth' : ''}`}
        onClick={this.closeSlideout}
      >

        <div id="event-slideout-wrapper" style={{ borderColor: borderColor }} ref={this.slideoutRef}>
          {
            eventContent &&
            <div ref={this.slideoutClose} style={{ borderColor: borderColor }} onClick={this.closeSlideout} id="close-slide">
              <p><span>Events</span></p>
            </div>
          }
          {
            eventContent &&
            <div id="event-slideout-content-wrapper" style={{paddingTop: 60}}>
              {
                eventContent.images.length > 0 &&
                <div className="item">
                  <Slider className="event-image-gallery"  {...settings}>
                    {
                      eventContent.images.map( image => {
                        return (
                          <div key={image.id} className="event-image-wrapper">
                            <div
                              className="event-image"
                              role="img"
                              // aria-label={ image.alt }
                              style={{ backgroundImage: `url(${ image.src })` }}>
                            </div>
                          </div>
                        )
                      })
                    }
                  </Slider>
                </div>
              }
              <div id="event-slideout-content">
                <div className="event-slideout-header">
                  {
                    ( eventContent.event_types || eventContent.event_themes ) &&
                    <p className="event-type">
                      <span style={{ background: 'black', color: 'white' }}>Discover</span>
                    </p>
                  }
                  <h1 className="h2" dangerouslySetInnerHTML={{ __html: eventContent.title ? eventContent.title : 'EVENT TITLE' }}></h1>
                  {
                    eventContent.social.length > 0 &&
                    <div className="event-social">
                      {
                        eventContent.social.map( social => {
                          return (
                            <a key={`social-${social.type}`} className={`social-link social-${social.type}`} target="_blank" href={social.link}>
                              <img src={socialIcons[social.type]}/>
                            </a>
                          )
                        })
                      }
                    </div>
                  }
                  {
                    eventContent.project_url &&
                    <a className="project-url bold uppercase text-button" style={{ color: 'black' }} href={eventContent.project_url}>Website</a>
                  }
                  <div className="event-sub-header">
                    {
                      eventContent.date && !eventContent.intro && eventContent.date.length === 1 &&
                      <div className="event-date">
                        <p>
                          <strong>
                            { formatPrettyDate(eventContent.date[0]) }
                            {
                              eventContent.end_date && ` - ${formatPrettyDate(eventContent.end_date)}`
                            }
                          </strong>
                        </p>
                        <p>
                          <span>{beautyTime(eventContent.start_time[0])}
                            {
                              eventContent.end_time.length > 0 && `- ${beautyTime(eventContent.end_time[0])}`
                            }
                          </span>
                        </p>
                      </div>
                    }
                  </div>
                </div>
                {
                  eventContent.content &&
                  <div className="event-content" dangerouslySetInnerHTML={{ __html: eventContent.content }}>
                  </div>
                }
                {
                  eventContent.contactInfo &&
                  <div>
                    {
                      eventContent.contactInfo.email &&
                      <p style={{ marginBottom: 0, marginTop: 0, fontWeight: 'bold' }}>Email: <a style={{ color: 'black' }} href={`mailto:${eventContent.contactInfo.email}`}>{ eventContent.contactInfo.email }</a></p>
                    }
                    {
                      eventContent.contactInfo.phone &&
                      <p style={{ marginBottom: 0, marginTop: 0, fontWeight: 'bold' }}>Phone: <a style={{ color: 'black' }} href={`tel:${eventContent.contactInfo.phone}`}>{ eventContent.contactInfo.phone }</a></p>
                    }
                  </div>
                }
                <div className="text-centered follow-nycjw">
                  <a href="https://instagram.com/nycjewelryweek" target="_blank"><img src={instagram}/><span>follow NYCJW on Instagram!</span></a>
                </div>
                {
                  eventContent.isAvailableSubmit &&
                  <div className="text-right">
                    <button type="button" className={`btn btn-submit-event ${(eventContent.title&&eventContent.title.length>0)?'':'disabled'}`} onClick={this.submitForm}>
                      { $('input[type="submit"]').val() }
                    </button>
                  </div>
                }
              </div>
            </div>
          }
          {
            loading &&
            <div className="event-loading-wrapper">
              <div className="event-loading">Loading</div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default PreviewVirtualBoothSlider;
