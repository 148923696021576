import React, { Component } from 'react'
import facebook from '../../images/fb.png';
import instagram from '../../images/instagram.png';
import twitter from '../../images/twitter.png';
import tiktok from '../../images/tiktok.png';
import appleIcon from '../../images/apple-app-store.png';
import googlePlayIcon from '../../images/google-play.png';
import Slider from "react-slick";
import $ from 'jquery';
import { formatPrettyDate, beautyTime } from '../events/utils';

const socialIcons = { facebook, instagram, tiktok, twitter }
class PreviewEventSlider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      closing: false,
      showInstructions: false
    };
    this.slideoutRef = React.createRef();
    this.slideoutClose = React.createRef();
    this.closeSlideout = this.closeSlideout.bind(this)
    this.toggleShowInstructions = this.toggleShowInstructions.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    const self = this;
    document.body.classList.add('modal-open');
    setTimeout(function() {
      self.setState({
        loading: false
      })
    }, 10);
  }

  submitForm() {
    $('form.acf-form').submit();
  }

  toggleShowInstructions () {
    this.setState({ showInstructions: !this.state.showInstructions })
  }

  closeSlideout (event) {
    const self = this;

    if (this.slideoutRef && !this.slideoutRef.current.contains(event.target) || this.slideoutClose.current.contains(event.target) ) {
      event.preventDefault()
      const timeout = window.screen.width < 768 ? 0 : 300
      document.body.classList.remove('modal-open');
      this.setState({
        closing: true
      }, () => {
        setTimeout( () => {
          self.setState({
            closing: false
          });
          self.props.dismissPreviewModal();
        }, timeout)
      })
    }
    return
  }
  render () {

    let { eventContent } = this.props;
    let { loading, closing, showInstructions } = this.state;
    let selectedTestDay = 1;
    let selectedEventType  = 'durational';
    let borderColor = '#FEF200'
    if ( eventContent ) {
      if ( eventContent.event_themes && eventContent.event_themes.length > 0 ) {
        borderColor = eventContent.event_themes[0].color?eventContent.event_themes[0].color:'#FEF200';
      }
    }
    const settings = {
      dots: false,
      infinite: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    let eventStatus = 'future'
    let rsvpLink = null
    let rsvpLabel = 'RSVP'
    if ( eventContent ) {
      let today = new Date()
      if ( selectedTestDay !== 1 ) {
        today = new Date(selectedTestDay + `T${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`)
      }
      let eventStartDate = new Date( eventContent.date + 'T09:00:00')
      let eventEndDate = new Date( eventContent.date + 'T23:59:59')
      const weekStartDate = new Date('2020-11-14T09:00:00')
      rsvpLink = eventContent.rsvp ? eventContent.rsvp.url : null
      // If IG event, set as follow us on IG
      if ( eventContent.instagram_event ) {
        rsvpLink = 'https://instagram.com/nycjewelryweek'
        rsvpLabel = 'Follow us to RSVP'
      }
      // If theres an RSVP link, set the label as its text
      if ( eventContent.rsvp ) {
        rsvpLabel = eventContent.rsvp.title ? eventContent.rsvp.title : 'RSVP'
      }
      // If the event is on now
      if ( ( today > eventStartDate && today < eventEndDate ) || ( selectedEventType === 'durational' && today >  weekStartDate ) ) {
        eventStatus = 'present'
        rsvpLabel = 'Watch'
        if ( eventContent.instagram_event ) {
          rsvpLink = 'https://instagram.com/nycjewelryweek/live'
        } else {
          rsvpLink = 'https://youtube.com'
        }
        rsvpLink = eventContent.link ? eventContent.link.url : rsvpLink
        if ( eventContent.link ) {
          rsvpLabel = eventContent.link.title ? eventContent.link.title : rsvpLabel
          rsvpLink = eventContent.link.url
        }

      }
      if ( today > eventEndDate && selectedEventType !== 'durational' ) {
        eventStatus = 'past'
        rsvpLink = true
        rsvpLabel = 'Event over'
      }
    }
    // If todays date is after the event end date
    return (
      <div
        id="event-slideout"
        className={`${loading ? 'loading' : ''}${eventContent ? 'loaded' : ''}${closing ? ' closing' : ''}`}
        onClick={this.closeSlideout}
      >

        <div id="event-slideout-wrapper" style={{ borderColor: borderColor }} ref={this.slideoutRef}>
          {
            eventContent &&
            <div ref={this.slideoutClose} style={{ borderColor: borderColor }} onClick={this.closeSlideout} id="close-slide">
              <p><span>Events</span></p>
            </div>
          }
          {
            eventContent &&
            <div id="event-slideout-content-wrapper" style={{paddingTop: 60}}>
              <div id="event-slideout-content">
                <div className="event-slideout-header">
                  {
                    ( eventContent.event_types || eventContent.event_themes.length > 0 ) &&
                    <p className="event-type">
                      <span style={{ background: 'black', color: 'white' }}>
                        { eventContent.virtual_event }
                      </span>
                      {
                        eventContent.event_types &&
                        <span style={{ background: '#fef200', color : 'black' }}>{ (eventContent.event_types&&eventContent.event_types.length>0)?eventContent.event_types[0].name:'' }</span>
                      }
                    </p>
                  }
                  <h2 dangerouslySetInnerHTML={{ __html: eventContent.title ? eventContent.title : 'EVENT TITLE' }}></h2>
                  {
                    eventContent.subtitle &&
                    <h3>{ eventContent.subtitle }</h3>
                  }
                  {
                    eventContent.project_url &&
                    <a className="project-url bold uppercase text-button" style={{ color: 'black', marginBottom: '1rem' }} href={eventContent.project_url}>Website</a>
                  }
                  {
                    ( eventContent.location_name && eventContent.virtual_event == 'irl' ) &&
                    <p style={{ marginBottom: 0 }}><strong>{ eventContent.location_name }</strong></p>
                  }
                  {
                    ( eventContent.location_address && eventContent.virtual_event == 'irl' ) &&
                    <p style={{ marginTop: 0, marginBottom: 0 }}>{ eventContent.location_address.address }</p>
                  }
                  {
                    ( eventContent.access_instructions && eventContent.virtual_event == 'irl' ) &&
                    <div style={{ marginBottom: '1.5rem' }}>
                      <sub onClick={this.toggleShowInstructions} style={{ textTransform: 'uppercase', marginBottom: '1rem', fontWeight: 'bold', cursor: 'pointer' }} className="underlined">Location Access Instructions</sub>
                      {
                        showInstructions && <p>{ eventContent.access_instructions }</p>
                      }
                    </div>
                  }
                  {
                    eventContent.social.length > 0 &&
                    <div className="event-social">
                      {
                        eventContent.social.map( social => {
                          return (
                            <a key={`social-${social.type}`} className={`social-link social-${social.type}`} target="_blank" href={social.link}>
                              <img src={socialIcons[social.type]}/>
                            </a>
                          )
                        })
                      }
                    </div>
                  }
                  {
                    eventContent.images &&
                    <div className="item">
                      <Slider className="event-image-gallery"  {...settings}>
                        {
                          eventContent.images.map( image => {
                            return (
                              <div key={image.id} className="event-image-wrapper">
                                <div
                                  className="event-image"
                                  role="img"
                                  // aria-label={ image.alt }
                                  style={{ backgroundImage: `url(${ image.src })` }}>
                                </div>
                              </div>
                            )
                          })
                        }
                      </Slider>
                    </div>
                  }
                  <div className="event-sub-header">
                    {
                      eventContent.date && !eventContent.intro && eventContent.date.length === 1 &&
                      <div className="event-date">
                        <p>
                          <strong>
                            { formatPrettyDate(eventContent.date[0]) }
                            {
                              eventContent.end_date && ` - ${formatPrettyDate(eventContent.end_date)}`
                            }
                          </strong>
                        </p>
                        {
                          eventContent.start_time.length > 0 &&
                          <p>
                            <span>{beautyTime(eventContent.start_time[0])}
                              {
                                eventContent.end_time.length > 0 && `- ${beautyTime(eventContent.end_time[0])}`
                              }
                            </span>
                          </p>
                        }
                      </div>
                    }
                  </div>
                </div>
                {
                  eventContent.date.length > 1 &&
                  <div className="event-date" style={{ marginBottom: '2rem' }}>
                    <h2>Event Dates</h2>
                    {
                      eventContent.date.map( (date, ind) => {
                        return (
                          <div>
                            <p style={{ marginBottom: 0, marginTop: 0 }}>
                              <strong>
                                { formatPrettyDate(date) }
                              </strong>
                              {
                                eventContent.start_time[ind] &&
                                  <span style={{ marginLeft: '1rem' }}>{beautyTime(eventContent.start_time[ind])}
                                    {
                                      eventContent.end_time[ind] && `- ${beautyTime(eventContent.end_time[ind])}`
                                    }
                                  </span>
                              }
                            </p>
                          </div>
                        )
                      })
                    }
                  </div>
                }
                {
                  eventContent.content &&
                  <div className="event-content" dangerouslySetInnerHTML={{ __html: eventContent.content }}>
                  </div>
                }
                <div className="partner" style={{ marginTop: '1.5rem' }}>
                  <div className="partner-header">
                    {
                      (eventContent.partnerName || eventContent.partner_website) &&
                      <h3>
                        {
                          eventContent.partner_name && <span>{ eventContent.partner_name }</span>
                        }
                        {
                          eventContent.partner_website &&
                          <a className="uppercase" href={eventContent.partner_website} target="_blank">
                            Website
                          </a>
                        }
                      </h3>
                    }
                  </div>
                  {
                    (eventContent.partnerName || eventContent.partner_website) && <div className="underlined" style={{ width: '100%' }}></div>
                  }
                  {
                    eventContent.in_gesso &&
                    <div className="partner-info">
                      <div className="partner-info-text">
                        <p>{ eventContent.partner_name ? eventContent.partner_name : 'This event' } is registered with Gesso. Download the app.</p>
                      </div>
                      <div className="partner-info-links-wrapper text-centered">
                        <div className="partner-info-links">
                          <a target="_blank" href="https://play.google.com/store/apps/details?id=app.gesso.android&hl=en_US">
                            <img src={googlePlayIcon}/>
                          </a>
                          <a target="_blank" href="https://apps.apple.com/ca/app/gesso-experiences/id1458855777">
                            <img src={appleIcon}/>
                          </a>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    eventContent.in_shop_week &&
                    <div className="partner-info">
                      <div className="partner-info-text">
                        <p>{ eventContent.partner_name ? eventContent.partner_name : 'This event' } is featured in our 'Shop The Week' page</p>
                      </div>
                      <div className="partner-info-links-wrapper text-centered">
                        <div className="partner-info-links">
                          <a className="btn" href="https://nycjewelryweek.com/shop-the-week">Shop The Week</a>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    eventContent.in_exhib &&
                    <div className="partner-info">
                      <div className="partner-info-text">
                        <p>{ eventContent.partner_name ? eventContent.partner_name : 'This event' } is featured in our 'Exhibitionist' page</p>
                      </div>
                      <div className="partner-info-links-wrapper text-centered">
                        <div className="partner-info-links">
                          <a className="btn" href="https://nycjewelryweek.com/exhibitionist">Exhibitionist</a>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                {
                  (eventContent.participants && eventContent.participants.length > 0) ?
                  <div className="event-participants">
                    <h4 className="underlined">Participants</h4>
                    <ul>
                      {
                        eventContent.participants.map( (participant, index) => {
                          return <li key={`participant-${index}`}>
                            <h5>{ participant.name }</h5>
                            {
                              participant.bio &&
                              <div className="participant-bio" dangerouslySetInnerHTML={{ __html: participant.bio }}>
                              </div>
                            }
                          </li>
                        })
                      }
                    </ul>
                  </div> : ''
                }
                {
                  (eventContent.supportEvents && eventContent.supportEvents.length > 1) ?
                  <div className="event-participants">
                    <h4 className="underlined">Supporting Events</h4>
                    <ul>
                      {
                        eventContent.supportEvents.map( (supportEvent, index) => {
                          return <li key={`support-event-${index}`}>
                            {
                              ( supportEvent.event_types && supportEvent.event_types.length > 0 ) &&
                              <p className="event-type">
                                {
                                  supportEvent.event_types &&
                                  <span style={{ background: 'black', color: 'white' }}>{ (supportEvent.event_types&&supportEvent.event_types.length>0)?supportEvent.event_types[0].name:'' }</span>
                                }
                              </p>
                            }
                            {
                              (supportEvent.event && supportEvent.event.length > 0) ?
                              <h5>{ supportEvent.event }</h5>
                              : ''
                            }
                            {
                              (supportEvent.location && supportEvent.location.address) &&
                              <p><strong>{supportEvent.location.address}</strong></p>
                            }
                            {
                              supportEvent.url &&
                              <a target="_blank" href={supportEvent.url}>{supportEvent.url}</a>
                            }
                            {
                              supportEvent.date && supportEvent.date.length > 1 &&
                              <div className="event-date">
                                <p>
                                  <strong>{ formatPrettyDate(supportEvent.date) }</strong>
                                </p>
                                {
                                  supportEvent.start_time && <p><span> {`${beautyTime(supportEvent.start_time)} - ${beautyTime(supportEvent.end_time)}`}</span></p>
                                }
                              </div>
                            }
                          </li>
                        })
                      }
                    </ul>
                  </div> : ''
                }
                <div className="text-centered follow-nycjw">
                  <a href="https://instagram.com/nycjewelryweek" target="_blank"><img src={instagram}/><span>follow NYCJW on Instagram!</span></a>
                </div>
              </div>
              {eventContent.isAvailableSubmit&&<div className="text-centered">
                <button type="button" className={`btn btn-submit-event ${(eventContent.title&&eventContent.title.length>0)?'':'disabled'}`} onClick={this.submitForm}>
                  { $('input[type="submit"]').val() }
                </button>
              </div>}
            </div>
          }
          {
            loading &&
            <div className="event-loading-wrapper">
              <div className="event-loading">Loading</div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default PreviewEventSlider;
